import React from "react"
import { Link } from "gatsby"

/* Import Local Style(s) */
import "./header.css"

const Header = () => (
  <div className="index-header">
    Welcome to The Portal. Here you will find an expanding index of work by
    artist, archivist, and curator <Link to="/about">Cori Olinghouse</Link>.
  </div>
)

export default Header
