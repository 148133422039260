import React from "react"
import { Link } from "gatsby"

/* Import Local Assets */
import PoolImageSrc from "./assets/pool.svg"

/* Import Local Styles */
import "./portal.css"

const Portal = () => (
  <div className="portal-wrapper">
    <Link to="/projects" className="portal">
      <img src={PoolImageSrc} alt="The Portal" />
    </Link>
    <Link to="/projects">Enter</Link>
  </div>
)

export default Portal
