import React, { Fragment } from "react"

/* Import Global Component(s) */
import SEO from "~components/seo"
import Page from "~components/page"

/* Import Local Component(s) */
import Header from "./index/components/header/_header"
import Portal from "./index/components/portal/_portal"

/* Import Local Style(s) */
import "./index/portal.css"

const Index = () => {
  return (
    <Fragment>
      <SEO title="Home" />
      <Page page="index" background="var(--color-background)">
        <Header />
        <Portal />
      </Page>
    </Fragment>
  )
}

export default Index
